import firebase from 'firebase/app'
import 'firebase/firestore'

export function useCpi(){

    const data = {
        cpi: [],
        _history: []
    }

    function getDocRef(){
        return firebase.firestore().collection("cpi").doc('default')
    }


    /**
     * 
     * @returns {Promise<Array<{year: Number, cpi: Number}>>}
     */

    async function getCpiList(){
        data.cpi = []
        const q = await getDocRef().get()
        q.data()?.data?.forEach(i => {
            data.cpi.push({
                year: i.year,
                cpi: i.cpi
            })
        });
        data.cpi.sort((a,b) => {
            return (b.year ?? 0) - (a.year ?? 0)
        })
        data._history = q.data()._history || []
        return data.cpi
    }

    /**
     * 
     * @param {Number} year 
     * @returns {Promise<Number>}
     */
    async function getCpiForYear(year){
        const r = await getCpiList()
        return r.filter(i=>i.year===year)?.[0]?.cpi
    }


    /**
     * 
     * @param {Number} year 
     * @param {Number} newCpi 
     */
    async function updateCpi(year, newCpi){
        if(!year || !newCpi){
            throw new Error("Invalid data")
        }
        const existingData = await getCpiList();
        const index = existingData.findIndex(i=>i.year===year)

        const historyData = {
                action: "update",
                year,
                oldValue: '',
                newValue: newCpi
        }

        if(index>-1){
            historyData.action = 'update'
            data._history.push(historyData)
            historyData.oldValue = existingData[index].cpi
            existingData[index] = {
                year: +year,
                cpi: +newCpi
            }
        }
        else{
            historyData.action = 'add'
            data._history.push(historyData)
            existingData.push({
                year: +year,
                cpi: +newCpi
            })
        }

        const newData = {
            data: existingData,
            _history: data._history
        }

        await getDocRef().update(newData)
        return {}
    }


    /**
     * 
     * @param {Number} year 
     */
    async function removeCpi(year){
        const existingData = await getCpiList();
        const filteredData = existingData.filter(i=>i.year!==year)
        const _history = data._history;

        _history.push({
                action: "delete",
                year,
                oldValue: existingData.filter(i=>i.year===year)[0]?.cpi,
                newValue: null
        })

        const newData = {
            data: filteredData,
            _history
        }

        await getDocRef().update(newData)
        return {}
    }


    /**
     * 
     * @param {Number} year 
     * @param {Number} number 
     */
    async function addCpi(year, cpi){
        return await updateCpi(year, cpi)
    }


    return {
        getCpiList,
        updateCpi,
        addCpi,
        data,
        getCpiForYear,
        removeCpi
    }
}