<template>
    <div>
        <v-simple-table>
            <thead>
                <tr>
                    <th>Year</th>
                    <th>CPI</th>
                    <th></th>
                </tr>
            </thead>
            <tbody v-if="data && data.cpi">
                <tr v-for="i in data.cpi" :key="i.year">
                    <td>
                        {{ i.year }}
                    </td>
                    <td>
                        {{ i.cpi }}
                    </td>
                    <td>
                        <v-btn icon x-small text @click="$emit('edit', i.year)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>

<script>
import { useCpi } from './useCpi'
const { data, getCpiList } = useCpi()

export default{
    data: () => {
        return {
            data
        }
    },
    mounted(){
        getCpiList()
    }
}
</script>