<template>
    <div>
        <slot></slot>
        <v-card class="mx-auto" max-width="600">
            <v-card-title class="d-flex">
                Update CPI
                <v-spacer></v-spacer>
                <v-btn icon small @click="onDelete" color="error">
                    <v-icon>mdi-trash-can</v-icon>
                </v-btn>
            </v-card-title>
            <v-container>
                <div v-if="editYear">
                    <v-text-field label="Year" v-model.number="editYear"></v-text-field>
                    <v-text-field label="CPI" v-model.number="editCpi"></v-text-field>
                    <v-btn @click="onSubmit" color="primary">Update</v-btn>
                    <v-btn class="ml-2" @click="onCancel">Cancel</v-btn>
                </div>
            </v-container>
        </v-card>
    </div>
</template>

<script>
import {useCpi} from './useCpi'
const cpi = useCpi()
export default{

    props: {
        year: {
            type: Number,
            required: true
        }
    },

    data: () => {
        return {
            editYear: '',
            editCpi: ''
        }
    },

    methods: {
        async onDelete(){
            const r = prompt("Enter 'DELETE' to remove CPI")
            if(r==='DELETE'){
                await cpi.removeCpi(this.editYear)
                this.$emit("success")
            }
        },
        async onSubmit(){
            try{
                await cpi.updateCpi(this.editYear, this.editCpi)
            }
            catch(e){
                alert(String(e))
            }
            this.$emit("success")
        },
        onCancel(){
            this.$emit("cancel")
        },
    },
    mounted(){
        cpi.getCpiForYear(this.year).then((cpi) => {
            this.editCpi = cpi
            this.editYear = this.year
        })
    }
}
</script>