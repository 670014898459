<template>
    <div>

        <div class="text-right">

            <v-btn class="text-none mr-2" small @click="mode= mode==='chart' ? 'list' : 'chart'">
                 <v-icon>mdi-chart-line</v-icon>
            </v-btn>


            <v-btn color="primary" class="text-none" small @click="mode='add'">
                Add CPI <v-icon>mdi-plus-circle</v-icon>
            </v-btn>
        </div>

        <CpiChart v-if="mode==='chart'"></CpiChart>
        <CpiList @edit="onEdit" v-if="mode==='list'"></CpiList>
        <CpiAdd v-if="mode==='add'" @success="mode='list'" @cancel="mode='list'">
            <template>
                <v-btn class="text-none" @click="mode='list'" text>
                    <v-icon>mdi-arrow-left</v-icon>
                    Cpi List 
                </v-btn>
            </template>
        </CpiAdd>
        <CpiEdit v-if="mode==='edit'" :year="editYear" @success="mode='list'" @cancel="mode='list'">
            <template>
                <v-btn class="text-none" @click="mode='list'" text>
                    <v-icon>mdi-arrow-left</v-icon>
                    Cpi List 
                </v-btn>
            </template>
        </CpiEdit>

    </div>
</template>

<script>
import CpiList from './List.vue'
import CpiEdit from './Edit.vue'
import CpiAdd from './Add.vue'
import CpiChart from './Chart.vue'

export default{
    components: {
        CpiList,
        CpiEdit,
        CpiAdd,
        CpiChart
    },
    data: () => {
        return {
            mode: 'list', // 'list', 'edit', 'add', 'chart'
            editYear: 0
        }
    },
    methods: {
        onEdit(year){
            this.editYear = year;
            this.mode = 'edit'
        }
    }
}

</script>