<template>
    <div>
        <slot></slot>
        <v-card class="mx-auto" max-width="600">
            <v-card-title>
                Add CPI
            </v-card-title>
            <v-container>
                <div>
                    <v-text-field label="Year" v-model.number="editYear"></v-text-field>
                    <v-text-field label="CPI" v-model.number="editCpi"></v-text-field>
                    <v-btn @click="onSubmit" color="primary">Add</v-btn>
                    <v-btn class="ml-2" @click="onCancel">Cancel</v-btn>
                </div>
            </v-container>
        </v-card>
    </div>
</template>

<script>
import {useCpi} from './useCpi'
const cpi = useCpi()
export default{

    data: () => {
        return {
            editYear: '',
            editCpi: ''
        }
    },

    methods: {
        async onSubmit(){
            try{
                await cpi.updateCpi(this.editYear, this.editCpi)
            }
            catch(e){
                alert(String(e))
            }
            this.$emit("success")
        },
        onCancel(){
            this.$emit("cancel")
        },
    },
}
</script>