import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("default"),_c(VCard,{staticClass:"mx-auto",attrs:{"max-width":"600"}},[_c(VCardTitle,{staticClass:"d-flex"},[_vm._v(" Update CPI "),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","small":"","color":"error"},on:{"click":_vm.onDelete}},[_c(VIcon,[_vm._v("mdi-trash-can")])],1)],1),_c(VContainer,[(_vm.editYear)?_c('div',[_c(VTextField,{attrs:{"label":"Year"},model:{value:(_vm.editYear),callback:function ($$v) {_vm.editYear=_vm._n($$v)},expression:"editYear"}}),_c(VTextField,{attrs:{"label":"CPI"},model:{value:(_vm.editCpi),callback:function ($$v) {_vm.editCpi=_vm._n($$v)},expression:"editCpi"}}),_c(VBtn,{attrs:{"color":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("Update")]),_c(VBtn,{staticClass:"ml-2",on:{"click":_vm.onCancel}},[_vm._v("Cancel")])],1):_vm._e()])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }