<script>
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables);
import { useCpi } from './useCpi';

const cpi = useCpi()

const chartData = (labels, data) => {
    return {
        type: 'bar',
        data: {
            labels: [...labels],
            datasets: [
                {
                    label: 'CPI',
                    data: [...data],
                }
            ]
        },
    }
}


export default {
    data: () => {
        return {
            data: cpi.data,
            chart: null
        }
    },
    methods: {
        initChart(){
            const el = document.getElementById("chart")
            const context = el.getContext('2d');

            const cpiData = this.data.cpi
            const labels = []
            const cpis = [];

            cpiData.forEach(i => {
                labels.push(i.year)
                cpis.push(i.cpi)
            });

            this.chart = new Chart(context, chartData(labels.reverse(), cpis.reverse()));
        }

    },
    beforeDestroy(){
        if(this.chart?.destroy){
            this.chart?.destroy()
        }
    },
    mounted() {
        cpi.getCpiList().then(() => {
            this.initChart()
        })
    }
}
</script>

<template>
    <div>
        <v-card class="mx-auto" max-width="1200" flat>
            <canvas id="chart"></canvas>
        </v-card>
    </div>
</template>